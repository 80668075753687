@import 'colors';

$width-btn-alert: 300px;
$height-btn-alert: 40px;

$width-box-alert: 100%;
$height-box-alert: 30px;
$margin-box-alert: 8px 30px 30px 0px;

$width-txtField-alert: 250px;
$height-txtField-alert: 30px;
$margin-txtField-alert: 30px 50px 20px 0px;

$width-select-alert: 250px;
$height-select-alert: 50px;
$margin-select-alert: 0px 0px 0px 0px;

$width-snackbar-success: 400px;
$height-snackbar-success: 50px;

.box-save-user {
  width: $width-box-alert;
  height: $height-box-alert;
  margin: $margin-box-alert;
}

.box-save-user {
  width: $width-box-alert;
  height: $height-box-alert;
  margin: 20px 30px 30px 0px;
}

.box-save-appointment {
  width: inherit;
  height: 70px;
  margin-top: 20px;
}

.box-form-appointment {
  height: 50px;
  margin-top: 20px;
  margin-bottom: 5px;
}
.box-form-other-appointment {
  height: 50px;
  margin-top: 60px;
  margin-bottom: 5px;
}

.modal-save-appointment {
  width: 300px;
  height: 400px;
  display: flex;
  margin-left: 23px;
}

.form-medical-card {
  width: 100%;
  height: 600px;
  margin: 8px 30px 30px 0px;
}

.header-table {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 2px 5px;
  margin: 20px 30px 30px 0px;
}

.text-area-description {
  width: 540px;
  height: $height-box-alert;
  margin: $margin-box-alert;
}

.text-area-description-ordonnance {
  width: 540px;
  height: $height-box-alert;
  margin-top: 30px;
}

.txtField-save-user {
  width: $width-txtField-alert;
  height: $height-txtField-alert;
}
.txtField-save-error-date {
  width: $width-txtField-alert;
  height: 53px;
  border: 1px   solid #FF1943;
  border-radius: 9px;
}
.sidebar {

  border: 1px   solid #FF1943;
  border-radius: 9px;
}

.txtField-save-appointment {
  width: 300px;
  height: $height-txtField-alert;
}

.date-picker-save-appointment {
  width: 300px;
  height: 30px;
}

.txtField-select-status {
  width: 200px;
  height: 50px;
}

.select-status-header {
  width: 100px;
  height: 50px;
}

.select-save-user {
  width: $width-select-alert;
  height: $height-select-alert;
  margin: $margin-select-alert;
}

.snackbar-success {
  color: $sucess;
  height: $height-snackbar-success;
  width: $width-snackbar-success;
}

.logo {
  margin-top: 15px;
  width: 250px;
}

.read-or-hide {
  color: rgb(192, 192, 192);
  cursor: pointer;
}

.modal-save-food-diaries {
  width: 270px;
  height: $height-txtField-alert;
 }

.modal-save-food-diaries-error {
  width: 270px;
  height: $height-txtField-alert;
}

.text-area-description-food-diaries {
  width: 550px;
   margin: 30px 5px 0px 0px;
}

.text-area-description-food-diary-period {
  width: 540px;
  height: $height-box-alert;
  margin: 45px 5px 0px 0px;
}

.save-food-diaries {
  height: 540px;
  width: 540px;
 }

.fc-day-header {
  font-size: 1.5em;
  color: $base_color;
}
