@import 'colors';
@import 'app';

.btn_login {
  justify-content: center;
  background-color: $base_color;
  color: $white;
  display: flex;
  &:hover {
    border: solid 2px;
    background-color: $base_color;
  } ;
}  ;